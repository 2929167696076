require('./bootstrap');

require('jquery-ui/jquery-ui');
require('jquery-ui/jquery-ui.theme.css');
require('jquery-ui/jquery-ui.css');

const swal = window.swal = require('sweetalert2');



